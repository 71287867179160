import { defineComponent, computed, ref, watch, reactive, getCurrentInstance, onMounted } from '@vue/composition-api';
import BackButton from '@/components/BackButton.vue';
import TrustPublication from '@/components/TrustPublication.vue';
import HelpdeskFooter from '@/Helpdesk/components/HelpdeskFooter.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
import { useGetFaqStoryQuery } from '@/generated-types/graphql.types';
import { useMeta } from '@/shared/composables/useMeta';
import { SITE_URL } from '@/App.vue';
import StoryblokService from '@/util/storyblok-service';
export default defineComponent({
    components: {
        BackButton,
        HelpdeskFooter,
        TrustPublication
    },
    setup(_, context) {
        const { root } = context;
        const instance = getCurrentInstance();
        const helpdeskCategorySlug = computed(() => root.$route.params.helpdeskCategorySlug);
        const helpdeskArticleSlug = computed(() => `${root.$route.params.helpdeskCategorySlug}/${root.$route.params.helpdeskArticleSlug}`);
        const article = ref(null);
        const story = ref(null);
        const preview = root.$route.query._storyblok_version === '';
        const queryVariables = reactive({
            slug: helpdeskArticleSlug.value,
            preview
        });
        const queryOptions = reactive({
            enabled: !!helpdeskArticleSlug.value
        });
        const { onResult, refetch } = useGetFaqStoryQuery(queryVariables, queryOptions);
        onResult((result) => {
            if (!result?.data?.get_faq_story)
                return;
            article.value = result.data.get_faq_story;
            story.value = JSON.parse(result.data.get_faq_story.raw_content);
            useMeta({
                title: story.value.content.metadata.title,
                description: story.value.content.metadata.description,
                url: `${SITE_URL}${window.location.pathname}`,
                noIndex: false
            });
        });
        watch(() => globalThis.$i18n.locale, locale => {
            queryVariables.slug = article.value
                ? `${helpdeskCategorySlug.value}/${article.value?.[`slug_${locale.toLowerCase()}`]}`
                : helpdeskArticleSlug.value;
            refetch(queryVariables);
        }, { immediate: true });
        watch(() => helpdeskArticleSlug.value, (newSlug, oldSlug) => {
            if (newSlug && newSlug !== oldSlug) {
                queryVariables.slug = newSlug;
                refetch();
            }
        }, { immediate: true });
        onMounted(() => {
            StoryblokService.initEditor(instance);
        });
        return {
            helpdeskCategorySlug,
            HelpdeskRoutes,
            story,
            useGetLocalizedPath
        };
    }
});
