import { defineComponent, ref, onMounted, computed, reactive, watch } from '@vue/composition-api';
import { SITE_URL } from '@/App.vue';
import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { useMeta } from '@/shared/composables/useMeta';
import { useGetAllFaqCategoryStoriesQuery } from '@/generated-types/graphql.types';
import qs from 'query-string';
import PagePagination from '@/components/PagePagination.vue';
import BackButton from '@/components/BackButton.vue';
import HelpdeskFooter from '@/Helpdesk/components/HelpdeskFooter.vue';
import HelpdeskStoryCard from '@/Helpdesk/components/HelpdeskStoryCard.vue';
import Spinner from '@/uikit/Spinner.vue';
import HelpdeskSearch from '@/Helpdesk/components/HelpdeskSearch.vue';
export default defineComponent({
    components: {
        BackButton,
        HelpdeskFooter,
        HelpdeskSearch,
        HelpdeskStoryCard,
        PagePagination,
        Spinner
    },
    setup(_, context) {
        const { root } = context;
        const helpdeskCategorySlug = computed(() => {
            const params = root.$route.params;
            return params.helpdeskCategorySlug;
        });
        const stories = ref({
            main: []
        });
        const page = ref(1);
        const pageSize = ref(10);
        const maxPage = ref(1);
        const queryVariables = reactive({
            slug: helpdeskCategorySlug.value,
            pagination: {
                page: page.value,
                size: pageSize.value
            }
        });
        const queryOptions = reactive({
            enabled: !!helpdeskCategorySlug.value
        });
        const { onResult, refetch, loading } = useGetAllFaqCategoryStoriesQuery(queryVariables, queryOptions);
        onResult((result) => {
            if (!result?.data?.get_all_faq_category_stories)
                return;
            maxPage.value = Math.ceil(result.data.get_all_faq_category_stories.total / 10);
            const _stories = result.data.get_all_faq_category_stories.stories;
            const data = _stories?.map((story) => {
                return {
                    title: story.name,
                    description: story.description,
                    text: story.truncated_content,
                    slug_en: story.slug_en,
                    slug_nl: story.slug_nl
                };
            });
            // group data stories by the description field value
            stories.value = data.reduce((acc, story) => {
                const key = story.description || 'main';
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(story);
                return acc;
            }, {});
            if (!data.length) {
                page.value = 1;
            }
            history.replaceState({}, null, page.value === 1 ? window.location.pathname : `?page=${page.value}`);
            useMeta({
                title: `${root
                    .$t(`helpdesk.categories.${helpdeskCategorySlug.value}.stories_header`)
                    .toString()} | WeMeet.nl`,
                description: root
                    .$t(`helpdesk.categories.${helpdeskCategorySlug.value}.description`)
                    .toString(),
                url: `${SITE_URL}${window.location.pathname}`,
                noIndex: false
            });
        });
        watch(() => page.value, () => {
            if (page.value) {
                queryVariables.pagination.page = page.value;
                refetch(queryVariables);
                root.$scrollTo('#stories', 500, {
                    offset: -200
                });
            }
        }, { immediate: true });
        watch(() => globalThis.$i18n.locale, () => {
            refetch(queryVariables);
        });
        onMounted(() => {
            const currentQueryParams = qs.parse(location.search, {
                arrayFormat: 'bracket'
            });
            page.value =
                currentQueryParams.page && Number(currentQueryParams.page) > 0
                    ? Number(currentQueryParams.page)
                    : 1;
            queryVariables.pagination.page = page.value;
            refetch(queryVariables);
        });
        return {
            helpdeskCategorySlug,
            loading,
            maxPage,
            stories,
            page,
            HelpdeskRoutes,
            useGetLocalizedPath
        };
    }
});
