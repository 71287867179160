import { computed, defineComponent } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { ChatRoutes } from '@/Chat/chat.const';
export default defineComponent({
    computed: {
        ChatRoutes() {
            return ChatRoutes;
        }
    },
    setup(_, __) {
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        return {
            user,
            useGetLocalizedPath
        };
    }
});
