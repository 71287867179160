import { defineComponent, computed } from '@vue/composition-api';
import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    props: {
        story: {
            type: Object,
            default: null
        },
        categorySlug: {
            type: String,
            required: true
        }
    },
    setup(props, __) {
        const storySlug = computed(() => {
            return props.story[`slug_${globalThis.$i18n.locale.toLowerCase()}`];
        });
        return {
            storySlug,
            HelpdeskRoutes,
            useGetLocalizedPath
        };
    }
});
