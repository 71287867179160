import { defineComponent } from '@vue/composition-api';
import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    props: {
        category: {
            type: Object,
            required: true
        }
    },
    setup(_, __) {
        return {
            HelpdeskRoutes,
            useGetLocalizedPath
        };
    }
});
