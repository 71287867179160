import { defineComponent, computed } from '@vue/composition-api';
import { useGetAllFaqCategoriesQuery } from '@/generated-types/graphql.types';
import HelpdeskCategoryCard from '@/Helpdesk/components/HelpdeskCategoryCard.vue';
import HelpdeskSearch from '@/Helpdesk/components/HelpdeskSearch.vue';
import HelpdeskFooter from '@/Helpdesk/components/HelpdeskFooter.vue';
import BackButton from '@/components/BackButton.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { useMeta } from '@/shared/composables/useMeta';
import { SITE_URL } from '@/App.vue';
export default defineComponent({
    components: {
        BackButton,
        HelpdeskCategoryCard,
        HelpdeskFooter,
        HelpdeskSearch
    },
    setup(_, context) {
        const { root } = context;
        const { result } = useGetAllFaqCategoriesQuery();
        const categories = computed(() => {
            return (result?.value?.get_all_faq_categories.map((category) => {
                return {
                    name: category[`faq_category_name_${globalThis.$i18n.locale.toLowerCase()}`],
                    slug: category[`faq_category_slug_${globalThis.$i18n.locale.toLowerCase()}`]
                };
            }) || []);
        });
        useMeta({
            title: root.$t('helpdesk.meta.title').toString(),
            description: root.$t('helpdesk.meta.description').toString(),
            url: `${SITE_URL}${window.location.pathname}`,
            noIndex: false
        });
        return { categories, useGetLocalizedPath };
    }
});
